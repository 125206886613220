import Custom500 from './500';
import { Metadata, PageContainer, CMSModule, BookingDropdown, ContactPopup } from 'components';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import nextI18nConfig from '../next-i18next.config';
import { setPageData, wrapper } from '../store/index';
import Header from '@/modules/Header';
import { getHost, getProtocol, getVirtualHost } from 'utils';

const Index = ({ errorCode, errorMessage, metadata, moduleContent, footer, header }) => {
  if (errorCode) {
    return <Custom500 errorCode={errorCode} errorMessage={errorMessage} />;
  }

  let slimHeroClass = header?.hotelNavigation
    ? '[&_.HeroSlimCenterAlignedModule]:lg:h-[calc(100vh-167px)]'
    : '[&_.HeroSlimCenterAlignedModule]:lg:h-[calc(100vh-104px)]';

  if (moduleContent?.find((module) => module.moduleName === 'AvailabilityBarModel')) {
    slimHeroClass =
      slimHeroClass +
      ' [&_.HeroSlimCenterAlignedModule_.text-wrapper]:pb-[63px] [&_.HeroSlimCenterAlignedModule_.text-wrapper]:md:pb-[71px] [&_.HeroSlimCenterAlignedModule_.text-wrapper]:lg:pb-[84px]';
  }

  return (
    <>
      <Metadata metadata={metadata} />
      <BookingDropdown availabilityData={header.availabilityBar} />
      <ContactPopup contactDetails={header.contactDetails} />
      <PageContainer className={slimHeroClass}>
        <Header
          header={header}
          metadata={metadata}
          filledHeader={moduleContent && moduleContent[0] && moduleContent[0].moduleName !== 'HeroCenterAlignedModule'}
        />

        {moduleContent?.map((module) => (
          <CMSModule key={module.moduleId} module={module} />
        ))}

        <CMSModule module={footer} />
      </PageContainer>
    </>
  );
};

export default Index;

export const getServerSideProps = wrapper.getServerSideProps((store) => async (context) => {
  const { resolvedUrl, locale, defaultLocale, res, req } = context;
  const host = getHost(req);
  const protocol = getProtocol(req);

  let errorCode,
    errorMessage = null;
  try {
    const dataRes = await fetch(
      `${protocol}//${host}/api${defaultLocale !== locale ? '/' + locale : ''}${resolvedUrl}`,
      {
        headers: { 'x-cdn-load': req.headers['x-cdn-load'] },
        keepalive: true,
      },
    );

    errorCode = dataRes?.ok ? false : dataRes?.status;

    const contentType = dataRes?.headers.get('content-type');

    if (errorCode === 500) {
      errorMessage =
        process.env.NODE_ENV === 'development'
          ? 'BE Internal Server Error'
          : "An error has occurred and we're working to fix the problem.";
    } else if (!contentType || contentType.indexOf('application/json') === -1) {
      if (errorCode === 404) {
        errorMessage = 'Page not found';
        res.statusCode = 404;
      } else {
        errorCode = 500;
        errorMessage =
          process.env.NODE_ENV === 'development'
            ? 'BE response not JSON Object'
            : "An error has occurred and we're working to fix the problem.";
        res.statusCode = 500;
      }
    } else if (!errorCode || errorCode === 404) {
      const data = await dataRes.json();

      if (data?.metadata?.pageType === 'sp404PageNotFound' && res) {
        res.statusCode = 404;
      }
      const isRobot = !!req.headers['is-robot'];

      store.dispatch(
        setPageData({ isRobot, pageId: data?.metadata?.pageId, host, virtualHost: getVirtualHost(req), protocol }),
      );

      res.setHeader(
        'Cache-Control',
        `public, max-age=${resolvedUrl.includes('isPreview=true') ? 0 : isRobot ? 2592000 : process.env.CDN_CACHE_MAX_AGE || 86400}`,
      );

      const translations = await serverSideTranslations(locale, ['common'], nextI18nConfig);

      return {
        props: {
          ...data,
          ...translations,
        },
      };
    }
  } catch (error) {
    errorCode = 500;
    console.error(error);
    errorMessage = error.message;
  }

  return {
    props: { errorCode, errorMessage },
  };
});
